import {Component, Input, OnInit} from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ConfigService } from '../../config.service';

@Component({
  selector: 'app-input-form',
  templateUrl: './input-form.component.html',
  styleUrls: ['./input-form.component.scss']
})
export class InputFormComponent {
  title = 'docker-image-downloader-ui';
  @Input() loading = false;
  downloadForm;
  errorMessage;

  constructor(private configService: ConfigService, private formBuilder: FormBuilder) {
    this.downloadForm = this.formBuilder.group({
      image: ''
    });
  }

  onSubmit(data) {
    this.loading = true;
    let image = data.image.split(':')[0];
    let tag = data.image.split(':')[1];
    this.configService.getData(() => {this.loading = false}, (error) => {this.errorMessage = error}, image, tag);
  }
}
