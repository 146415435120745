import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { saveFile } from './file-download-helper';


@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  apiUrl = 'https://api.docker-image-downloader.de/download';

  constructor(private http: HttpClient) { }


  async getData(onEnd, onError, image: string, tag: string) {
    let params = new HttpParams().set('image', image);
    if (tag !== undefined) params = params.set('tag', tag);
    let requestWithParams = this.apiUrl + '?image=' + image + tag !== undefined ? '&tag=' + tag : '';

    let httpHeaders = new HttpHeaders({
      'Content-Type': 'application/octet-stream'
    });

    return this.http.get(this.apiUrl, { 'params': params, 'headers': httpHeaders, 'responseType': 'blob' })
      .subscribe((res: Blob) => {
        if(!tag) tag = 'latest';
        const fileName = image + '_' + tag + '.tar.gz';
        saveFile(res, fileName);
        onEnd();
      });
  }
}
