

    <div class="card-body">
      <form [formGroup]="downloadForm" (ngSubmit)="onSubmit(downloadForm.value)">
        <div class="input-group mb-3 shadow-sm">
          <div class="input-group-prepend">
            <span class="input-group-text">&lt;image&gt;:&lt;tag&gt;</span>
          </div>
          <input required minlength="4" id="image" formControlName="image" class="form-control form-control-lg"
                 type="text" placeholder="e.g. nginx:mainline">
        </div>
        <div class="form-row text-center">
          <button [disabled]="!downloadForm.valid || loading" type="submit"
                  class="btn btn-primary btn-lg" style="margin-left: 45%; ">
            <span *ngIf="loading" class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
            {{ loading ? "Please wait..." : "Download"}}
          </button>
        </div>
      </form>
      <div *ngIf="errorMessage"  class="alert alert-danger" role="alert">
        {{ errorMessage }}
      </div>
    </div>


